

import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'
import AgbTextDe from './AGBTexts/AgbTextDe.vue'
import AgbTextEn from './AGBTexts/AgbTextEn.vue'

export default defineComponent({
  setup() {
    // when set to false, show privacy policy of app
    const webPolicy = ref(false)

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { t, webPolicy, i18n }
  },

  components: {
    AgbTextEn,
    AgbTextDe
  },

  methods: {
    showWebPolicy() {
      this.webPolicy = true
    },
    showAppPolicy() {
      this.webPolicy = false
    }
  }
})
