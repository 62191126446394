import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24e6f714"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = {
  class: "col-11 col-md-8",
  style: {"margin-top":"40px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_agb_text_de = _resolveComponent("agb-text-de")!
  const _component_agb_text_en = _resolveComponent("agb-text-en")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.i18n.global.locale.value.includes('de'))
          ? (_openBlock(), _createBlock(_component_agb_text_de, { key: 0 }))
          : (_openBlock(), _createBlock(_component_agb_text_en, { key: 1 }))
      ])
    ])
  ]))
}